import React, { useRef } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from 'react-google-maps';

import useGMapVisible from '@hooks/useGMapVisible';
import mapStyles from './mapStyles';

interface IGMapProps {
  lat: number;
  lng: number;
}

const GMap: React.FC<IGMapProps> = ({ lat, lng }) => {
  const wrapperRef = useRef(lat);
  const refMap = useRef(null);

  const { visible, handleVisible, unHandleVisible } = useGMapVisible(wrapperRef, refMap, lat);
  const directions_url =
    'https://www.google.com/maps/dir/?api=1&destination=' + encodeURI(lat + ',' + lng);

  return (
    <GoogleMap
      ref={refMap}
      onClick={unHandleVisible}
      zoom={15}
      center={{ lat, lng }}
      defaultOptions={{ styles: mapStyles, disableDefaultUI: true }}
    >
      <Marker position={{ lat, lng }} onClick={handleVisible}>
        {visible && (
          <InfoWindow position={{ lat, lng }} onCloseClick={unHandleVisible}>
            <div>
              <h3>
                <a href={directions_url} target="_blank">
                  Get Directions
                </a>
              </h3>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  );
};

export const WrappedMap = withScriptjs(withGoogleMap(GMap));
