import React from 'react';

import { WrappedMap } from '@components/GoogleMap/WrappedMap';

import { GOOGLE_MAP_URL, GOOGLE_MAP_API_KEY } from '@utils/constants';

interface IGoogleMapContainer {
  lat: number;
  lng: number;
}

const GoogleMapContainer: React.FC<IGoogleMapContainer> = ({ lat, lng }) => {
  return (
    <WrappedMap
      googleMapURL={`${GOOGLE_MAP_URL}${GOOGLE_MAP_API_KEY}`}
      loadingElement={<div className="map__element" />}
      containerElement={<div className="map__container__element" />}
      mapElement={<div className="map__element" />}
      lat={lat}
      lng={lng}
    />
  );
};

export default GoogleMapContainer;
