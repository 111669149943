import { useState, useEffect } from 'react';

const MIN_HEIGHT = 960;
const MAX_HEIGHT = 1199;

const useGMapVisible = (wrapperRef: React.MutableRefObject<number>, refMap: any, lat: number) => {
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    if (wrapperRef.current !== lat) {
      setVisible(false);
    }
    wrapperRef.current = lat;
  }, [lat]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        setSize(window.innerWidth);
      }

      if (size < MAX_HEIGHT && size > MIN_HEIGHT) {
        refMap.current.panBy(-175, 0);
      }
      if (size < MIN_HEIGHT) {
        refMap.current.panBy(0, 0);
      }

      window.addEventListener('resize', handleResize);
    }
  });

  function handleVisible() {
    setVisible(true);
  }

  function unHandleVisible() {
    setVisible(false);
  }

  return { visible, handleVisible, unHandleVisible };
};

export default useGMapVisible;
