import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import _chunk from 'lodash/chunk';
import { gsap, Power3, Power4 } from 'gsap';

import Layout from '@components/Layout';
import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';

import GoogleMapContainer from '@components/GoogleMap';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

type TGPSCoordinates = {
  latitude: number;
  longitude: number;
};

type TLocations = {
  heading1: string[];
  address: string[];
  gps_coordinates: TGPSCoordinates;
};

interface IOurDepotsProps {
  data: {
    prismic: {
      allOur_depots_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const runAnimations = () => {
  const tlDepots = gsap.timeline();

  tlDepots.fromTo('.anim-depots-title', {
    transform: "translate(-50%, 0)",
  }, {
    transform: "translate(0%, 0)",
    ease: Power3.easeInOut,
    duration: 1,
  });

  tlDepots.fromTo('.anim-depots-title', {
    opacity: 0,
  }, {
    opacity: 1,
    ease: Power3.easeIn,
    duration: 1,
  }, "-=1");

  tlDepots.fromTo('.anim-depots-block', {
    transform: "translate(-50%, 0)",
  }, {
    transform: "translate(0%, 0)",
    ease: Power3.easeInOut,
    duration: 1,
  });

  tlDepots.fromTo('.anim-depots-block', {
    opacity: 0,
  }, {
    opacity: 1,
    ease: Power3.easeIn,
    duration: 1,
  }, "-=1");

  tlDepots.fromTo('.anim-depots-map', {
    opacity: 0,
  }, {
    opacity: 1,
    ease: Power4.easeIn,
    duration: 0.7,
  });
};

const OurDepots: React.FC<IOurDepotsProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allOur_depots_pages');
  if (!doc) return null;

  const [act, setAct] = useState(0);
  const [pageAnimations, setPageAnimations] = useState(false);

  const { latitude, longitude } = doc.node.locations[act].gps_coordinates;
  const [lat, setLat] = useState(latitude);
  const [lng, setLng] = useState(longitude);

  const chunkLocations: TLocations[] = doc.node.locations;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  useEffect(() => {
    if(typeof window !== "undefined") {
      if(pageAnimations === false) {
        setPageAnimations(true);
        runAnimations();
      }
    }
  });

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : 'Our Depots'}
        description={page_description && RichText.render(page_description)}
        firstLevelPage
        seo={{
          path: ENUM_STATIC_MENU_LINKS['OUR DEPOTS'],
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image.url : null,
        }}
        specLines='light'
      >
        <div className="depots">
          <div className="depots__content">
            <div className="depots__text">
              <div className="anim-depots-title">
                <h1>{RichText.asText(doc.node.heading)}</h1>
                {RichText.render(doc.node.description_text, linkResolver)}
              </div>

              <div className="depots__addresses anim-depots-block">
                {chunkLocations.map((location, index) => {
                  const changeMap = () => {
                    if(typeof window !== 'undefined' && window.matchMedia('(min-height: 800px) and (min-width: 960px)').matches) {
                      setLat(location.gps_coordinates.latitude);
                      setLng(location.gps_coordinates.longitude);
                      setAct(index);
                    }
                  };

                  const map_link = 'https://www.google.com/maps/search/?api=1&query='+ encodeURI(location.gps_coordinates.latitude +','+ location.gps_coordinates.longitude);

                  return (
                    <div className='depots__address' key={index} onClick={changeMap}>
                      <div className={classNames('depots__address-link', {
                        'depots__address-link--active': index === act,
                      })}>
                        <h3>{RichText.asText(location.heading1)}</h3>
                        {RichText.render(location.address, linkResolver)}
                      </div>
                      <a href={map_link} target="_blank">Google maps</a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="depots__map anim-depots-map">
              <GoogleMapContainer lat={lat} lng={lng} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query OurDepots {
    prismic {
      allOur_depots_pages {
        edges {
          node {
            description_text
            heading
            locations {
              heading1
              address
              gps_coordinates
            }
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OurDepots;
